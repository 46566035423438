/*
#***********************************************
#
#      Filename: src/api/ParkingSalesStatistics/index.js
#
#        Author: xiaowang 1839648563@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位销售统计
#        Create: 2023-06-13 10:09:28
# Last Modified: 2023-06-13 10:09:28
#***********************************************
*/

import request from '@/utils/request'

// 主列表接口
export function list(data) {
  return request({
    url: '/sellinfo/statInfo',
    method: 'get',
    params: data
  })
}
