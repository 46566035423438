<!--
#***********************************************
#
#      Filename: src/views/List/ParkingSpaceSales.vue
#
#        Author: xiaowang - 1839648563@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位销售
#        Create: 2023-06-05 15:28:15
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :permissions="permissions"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @refresh="handleRefresh"></ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border>
      <el-table-column
        v-for="(item, index) in tableHeadFields"
        :key="index"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :prop="item.prop">
        <template slot-scope="scope">
          <template v-if="index == 1 || index == 2 || index == 3 || index == 4">
            &yen;&nbsp; {{ scope.row[item.prop].toFixed(2) }}
          </template>
          <template v-else>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->

    <!-- end 添加修改dialog -->
    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'
  // eslint-disable-next-line no-unused-vars
  import { list } from '@/api/ParkingSalesStatistics'

  export default {
    name: 'Parking',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],
    data() {
      return {
        // 编辑添加弹框 付款项
        parkingForm: {
          moneyTitle: '',
          money: ''
        },
        addList: [],
        // 编辑表单数据验证规则
        rules: {
          placeNo: [{ required: true, message: '车位号不能为空', trigger: 'blur' }],
          cqsNo: [{ required: true, message: '产权室号不能为空', trigger: 'blur' }],
          remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }]
        },
        // 组件头部配置项
        settings: {
          left: {
            totalItemShow: false,
            searchShow: false,
            searchShows: true,
            searchShowis: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '车位号/购买人/租赁人/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: false,
            downloadTemplateShow: false,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: []
          },
          searchDateWords: {
            arr: [
              {
                title: '销售日期：',
                type: 'dateTimeRange',
                name: 'dateRange'
              },
              {
                title: '付款日期：',
                type: 'dateTimeRange',
                name: 'dateRanges'
              }
            ]
          }
        },
        parkingData: [],
        startTime: ' ',
        endTime: ' ',
        startTimes: ' ',
        endTimes: ' ',
        // 主列表字段
        tableHeadFields: [
          {
            label: '销售车位(个)',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'sellCount'
          },
          {
            label: '销售金额(元)',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'sellFee'
          },
          {
            label: '实收款(元)',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'receiveFee'
          },
          {
            label: '未收款(元)',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'disReceiveFee'
          },
          {
            label: '退款(元)',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'refundFee'
          }
        ],

        // 主列表绑定字段
        dataList: [],
        permissions: {
          create: 'report:sellinfo:statistics:create',
          info: 'report:sellinfo:statistics:info',
          delete: 'report:sellinfo:statistics:delete',
          update: 'report:sellinfo:statistics:update',
          export: 'report:sellinfo:statistics:export',
          import: 'report:sellinfo:statistics:import'
        }
      }
    },

    computed: {},

    mounted() {
      this.getList()
    },

    methods: {
      // 主列表请求
      getList() {
        this.parkingData = JSON.parse(localStorage.getItem('GZ-ParkingList'))
        this.loading = true
        if (this.filterData?.dateRange) {
          this.startTime = moment(this.filterData?.dateRange[0]).format('YYYY-MM-DD HH:mm:ss') || ''
          this.endTime = moment(this.filterData?.dateRange[1]).format('YYYY-MM-DD HH:mm:ss') || ''
        }
        if (this.filterData?.dateRanges) {
          this.startTimes = moment(this.filterData?.dateRanges[0]).format('YYYY-MM-DD HH:mm:ss') || ''
          this.endTimes = moment(this.filterData?.dateRanges[1]).format('YYYY-MM-DD HH:mm:ss') || ''
        }
        const data = {
          sellStartTime: this.startTime,
          sellEndTime: this.endTime,
          parkingId: this.pid,
          receiveStartTime: this.startTimes,
          receiveEndTime: this.endTimes,
          communityId: this.communityId
        }
        console.log(data)
        list(data)
          .then((res) => {
            if (this.startTime || this.endTime) {
              this.startTime = ''
              this.endTime = ''
            }
            if (this.startTimes || this.endTimes) {
              this.startTimes = ''
              this.endTimes = ''
            }
            if (res.result) {
              this.dataList = res.data
            } else {
              this.noticeError('业务处理失败，请联系管理员')
            }
          })
          .catch((err) => {
            console.log(err, '+++')
          })
          .finally(() => {
            this.loading = false
          })
      },
      // 页面刷新
      handleRefresh() {
        this.getList()
      }
    }
  }
</script>
